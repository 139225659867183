/* eslint-disable func-names */
// import { select2 } from 'select2';
import selectize from "./lib/selectize";
import { PragmaTabs } from "./lib/pragmatica/tabs";

export default function controls() {
  const tabs = new PragmaTabs(".pragma-tabs");

  window.tabs = tabs;

  (function navigation(params) {
    const button = document.querySelector(".nav-menu__toggle");
    const menuList = document.querySelector(".nav-menu__wrapper");

    function menuToggle(e) {
      button.classList.toggle("nav-menu_open");
      // freeze();
    }

    button.addEventListener("click", menuToggle);
  })();

  setTimeout(function () {
    $(".js-select").selectize();
  }, 100);

  /* eslint-disable */
  // let map;

  // function initMap() {
  //   map = new google.maps.Map(document.getElementById("map-1"), {
  //     center: { lat: -34.397, lng: 150.644 },
  //     zoom: 8,
  //   });
  // }
  // window.initMap = initMap;

  /* eslint-enable */

  // setTimeout(function() {
  //     $('.video-player').click(function() {
  //         const video = $(this)[0];
  //         const button = $(this).parent()
  //         if (video.paused) {
  //             video.play();
  //             button.addClass('playing')
  //         } else {
  //             video.pause();
  //             button.removeClass('playing')
  //         }
  //     });
  // }, 500)
}
