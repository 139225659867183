// import smoothscroll from "smoothscroll-polyfill";
import sliders from "./sliders";
import modals from "./modals";
import controls from "./controls";
import animation from "./animation";
import checkBrowsers from "./lib/checkBrowsers";

// smoothscroll.polyfill();

global.ww = window.innerWidth;
global.wh = window.innerHeight;

$(document).ready(() => {
  document.body.className += " ready";
  global.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  // const zoom = function () {
  //   const width = window.innerWidth;
  //   if (width < 1920) {
  //     document.body.style.zoom = width / 1920 + 0.1;
  //   }
  // };

  controls();
  sliders();
  modals();
  animation();
  // zoom();

  const allResponsives = () => {
    global.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    // zoom();

    // console.log("allResponsives!");
  };
  allResponsives();

  $(window).on("resize", function () {
    global.ww = window.innerWidth;
    global.wh = window.innerHeight;
    allResponsives();
  });
});
