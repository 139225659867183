import Swiper, { Navigation, Pagination } from "swiper";

export default function sliders() {
  (function circleSlider() {
    // Install modules
    Swiper.use([Navigation, Pagination]);
    const swiper = new Swiper(".circle-slider", {
      slidesPerView: 3,
      spaceBetween: 20,
      watchOverflow: true,
      pagination: {
        el: ".circle-slider-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".circle-slider-button-next",
        prevEl: ".circle-slider-button-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
      },
    });
  })();
  (function photoSlider() {
    // Install modules
    Swiper.use([Navigation, Pagination]);
    const swiper = new Swiper(".photo-slider", {
      slidesPerView: 1,
      watchOverflow: true,
      pagination: {
        el: ".photo-slider-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".photo-slider-button-next",
        prevEl: ".photo-slider-button-prev",
      },
    });
  })();

  (function newsSlider() {
    // Install modules
    Swiper.use([Navigation, Pagination]);
    const swiper = new Swiper(".news-hero__slider", {
      slidesPerView: 1,
      loop: true,
      watchOverflow: true,
      pagination: {
        el: ".news-hero-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".news-hero-button-next",
        prevEl: ".news-hero-button-prev",
      },
    });
  })();
  (function simpleSlider() {
    // Install modules
    Swiper.use([Navigation, Pagination]);
    const swiper = new Swiper(".simple-slider", {
      slidesPerView: 2,
      spaceBetween: 15,
      watchOverflow: true,
      navigation: {
        nextEl: ".simple-slider-button-next",
        prevEl: ".simple-slider-button-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: "auto",
          spaceBetween: 70,
        },
      },
    });
  })();
}
/* eslint-enable import/no-unresolved */
