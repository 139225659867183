// import "./lib/jquery.validate";
import "./lib/jquery.maskedinput";
import Pristine from "pristinejs";
// import "magnific-popup";

export default function modals() {
  const header = document.querySelector(".header");
  const modalCity = document.querySelector(".popup-city");
  const modalWork = document.querySelector(".popup-work");
  let btnWorkText;
  let btnCloseContent;
  function closeAllModals() {
    const button = document.querySelector(".nav-menu__toggle");
    if (button.classList.contains("nav-menu_open")) {
      button.classList.remove("nav-menu_open");
    }

    if (modalCity.classList.contains("popup_show")) {
      modalCity.classList.remove("popup_show");
      document.body.classList.remove("change-city_opened");
    }
  }
  (function searchCity() {
    const search = document.querySelector("#searchCity");
    const list = document.querySelectorAll(".popup-city__group");
    let sort;

    function checkCity() {
      const cities = [];
      list.forEach((group) => {
        const category = group;
        const cityList = group.querySelectorAll(".popup-city__item");
        cityList.forEach((object) => {
          let city = object;
          city.classList.add("hidden");
          cities.push(city);
          const formatValue =
            search.value.charAt(0).toUpperCase() + search.value.slice(1);
          sort = cities.filter((item) =>
            item.textContent.includes(formatValue)
          );
          sort.forEach((valid) => {
            city = valid;
            city.classList.remove("hidden");
          });
        });
        if (
          category.querySelectorAll(".popup-city__item").length ===
          category.querySelectorAll(".hidden").length
        ) {
          category.style.display = "none";
        } else {
          category.style.display = "block";
        }
      });
    }
    search.addEventListener("keyup", checkCity);
  })();
  (function changeCity() {
    if (!modalCity) return;

    const trigger = document.querySelectorAll("[data-change-city]");
    const closeBtn = document.querySelector(".popup-city__close");

    trigger.forEach((item) => {
      item.addEventListener("click", (e) => {
        e.preventDefault();
        closeAllModals();
        modalCity.classList.add("popup_show");
        document.body.classList.add("change-city_opened");
      });
    });

    closeBtn.addEventListener("click", (e) => {
      e.preventDefault();
      modalCity.classList.remove("popup_show");
      document.body.classList.remove("change-city_opened");
    });
  })();

  (function workUs() {
    if (!modalWork) return;

    const trigger = document.querySelectorAll("[data-work]");
    const modalTitle = document.querySelector(".popup-work__title");
    const modalMobileClose = document.querySelector(".popup-work__close");

    trigger.forEach((item) => {
      const element = item;

      const dataForm = document.querySelector("#dataForm");

      const isClose = document.querySelector("[data-work-close]");
      btnWorkText = isClose.textContent;
      btnCloseContent = isClose.dataset.workClose;
      element.addEventListener("click", (e) => {
        e.preventDefault();
        closeAllModals();
        if (!element.classList.contains("active")) {
          if (element.dataset.title) {
            modalTitle.textContent = element.dataset.title;
          } else {
            modalTitle.textContent = element.textContent;
          }
          if (element.dataset.from) {
            dataForm.value = element.dataset.from;
          } else {
            dataForm.value = element.textContent;
          }

          modalWork.classList.add("popup_show");
          document.body.classList.add("workUs_opened");
          isClose.classList.add("active");
          isClose.textContent = btnCloseContent;
          header.classList.add("header_light");
        } else {
          modalWork.classList.remove("popup_show");
          document.body.classList.remove("workUs_opened");
          isClose.classList.remove("active");
          isClose.textContent = btnWorkText;
          header.classList.remove("header_light");
        }
      });
      modalMobileClose.addEventListener("click", (e) => {
        e.preventDefault();
        modalWork.classList.remove("popup_show");
        document.body.classList.remove("workUs_opened");
        header.classList.remove("header_light")
      });
    });
  })();

  //   window.onload = function () {
  $('input[type="tel"]').mask("+7 (999) 999-99-99");

  const formList = document.querySelectorAll("[data-form-validate]");

  formList.forEach((form) => {
    const validator = new Pristine(form, null, false);

    const selectSelectize = form.querySelectorAll(".js-select");
    function selectClear() {
      selectSelectize.forEach((item) => {
        const field = item;
        field.selectize.clear();
      });
    }
    const successMessage = form.querySelector(".form-success__message");
    form.addEventListener("submit", function (e) {
      e.preventDefault();
      const valid = validator.validate();
      if (valid) {
			const aparams = $(form).serialize();
			$.post("/mail.php", aparams, function( data ) {
   			 //do some stuff with the data
			});
			//alert(`TEST ${aparams}`);
        form.reset();
        selectClear();
        successMessage.style.display = "block";
			
      } else {
        successMessage.style.display = "none";
      }
    });
  });
  //   };
}
