import { gsap } from "gsap";
import { ScrollTrigger, ScrollToPlugin } from "gsap/all";
// import { ScrollScene } from 'scrollscene'
import { SmoothScroll } from "./lib/SmoothScroll";

export default function animation() {

  (function preloader() {
    const video = document.querySelector("#heroStart");
    if (!video) {
      document.body.classList.remove("freeze");
      document.body.classList.add("animated");
      return;
    }

    function startAnimate(elVideo) {
      document.body.classList.add("animated");
        setTimeout(() => {
          if(elVideo) {
            elVideo.play();
          }
          document.body.classList.remove("freeze");
        }, 2700);
    }
    function checkLoad() {
      if (video.readyState === 4) {
        startAnimate(video)
      } else {
        setTimeout(startAnimate(video), 500)
      }
    }
    if (document.readyState !== "loading") {
      checkLoad()
    } else {
        document.addEventListener("DOMContentLoaded", checkLoad);
    }

  })();

  function closeVideos() {
    const videosPlay = document.querySelectorAll(".video-play");
    if (!videosPlay) return;

    for (let i = 0; i < videosPlay.length; i++) {
      const item = videosPlay[i];
      const video = item.querySelector("video");

      item.classList.remove("video-play");
      video.pause();
    }
  }

  document.body.addEventListener("click", function (e) {
    closeVideos();
  });

  (function videoPlayer() {
    const container = document.querySelectorAll(".video-block");

    container.forEach((item) => {
      item.addEventListener("click", (e) => {
        const videoBlock = e.target.closest(".video-player");
        if (videoBlock) {
          e.stopPropagation();
          const video = videoBlock.querySelector("video");
          const progressLine = videoBlock.querySelector(".video-progress-line");

          video.addEventListener("timeupdate", (data) => {
            if (progressLine) {
              let progress = 0;
              progress = (100 / video.duration) * video.currentTime;
              progressLine.style.width = `${progress}%`;
            }
          });
          video.onended = function () {
            videoBlock.classList.remove("video-play");
            if (progressLine) {
              let progress = 0;
              progress = (100 / video.duration) * video.currentTime;
              progressLine.style.width = `${progress}%`;
              setTimeout(function () {
                progressLine.style.width = "0%";
              }, 300);
            }
          };
          if (!videoBlock.classList.contains("video-play")) {
            closeVideos();
            video.play();
            videoBlock.classList.add("video-play");
          } else {
            video.pause();
            videoBlock.classList.remove("video-play");
          }
        }
      });
    });
  })();

  (function scrollTo() {
    const links = document.querySelectorAll("[data-scroll-to]");
    const header = document.querySelector(".header");

    if (!links) return;

    links.forEach((item) => {
      item.addEventListener("click", (e) => {
        const inThisPage = document.querySelector(item.hash);
        if (!inThisPage || !item.hash) return;
        e.preventDefault();

        const block = document.querySelector(item.hash);

        $("html, body").stop().animate(
          {
            scrollTop: block.offsetTop,
          },
          500
        );
      });
    });
  })();

  (function mainPageAnimations() {
    gsap.registerPlugin(ScrollTrigger);
    // block brand main
    if (document.querySelector(".main-brand")) {
      const brandMain = gsap.timeline({
        scrollTrigger: {
          trigger: ".main-brand .about-brand__main",
          start: "top bottom",
          end: `bottom center`,
          scrub: 0.5,
          markers: false,
        },
      });
      brandMain
        .fromTo(
          ".main-brand .about-brand__text",
          { scale: 1.3 },
          { scale: 1, duration: 1 }
        )
        .fromTo(
          ".circle-brand__animate_one",
          { yPercent: -60, scale: 1 },
          { yPercent: 10, scale: 0.6, duration: 1.5 },
          "-=1"
        )
        .fromTo(
          ".circle-brand__animate_two",
          { yPercent: 40, scale: 1 },
          { yPercent: -90, scale: 0.7, duration: 1 },
          "-=1.3"
        );
      // block brand show
      const brandShow = gsap.timeline({
        scrollTrigger: {
          trigger: ".main-brand .about-brand__show",
          start: "top bottom",
          end: `bottom center`,
          scrub: 0.5,
          markers: false,
        },
      });
      brandShow.fromTo(
        ".about-brand__show",
        { scale: 1.2, yPercent: 15, alpha: 0.3 },
        { scale: 1, yPercent: 0, alpha: 1, duration: 0.5 }
      );

      // block brand more
      const brandMore = gsap.timeline({
        scrollTrigger: {
          trigger: ".main-brand .about-brand-more",
          start: "top bottom",
          end: `bottom center`,
          scrub: 0.5,
          markers: false,
        },
      });
      brandMore
        .fromTo(
          ".about-brand-more__video",
          { top: -100 },
          { top: 200, duration: 0.5 }
        )
        .fromTo(
          ".about-brand__lead",
          { scale: 0.7, yPercent: -20 },
          { scale: 1, yPercent: 0, duration: 0.5 },
          "-=0.5"
        )
        .fromTo(
          ".about-brand__image_hair",
          { yPercent: -55 },
          { yPercent: 55, duration: 0.5 },
          "-=0.5"
        )
        .fromTo(
          ".about-brand__image_after",
          { yPercent: 50 },
          { yPercent: -100, duration: 0.5 },
          "-=0.5"
        );
    }

    const productShows = document.querySelectorAll(".product-show__item");
    if (productShows) {
      productShows.forEach((item) => {
        const productShow = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: "top bottom",
            end: `bottom center`,
            scrub: 0.5,
          },
        });

        productShow
          .fromTo(
            item.querySelector(".product-show__background_image"),
            { scale: 1.2 },
            { scale: 1, duration: 1.5 }
          )
          .fromTo(
            item.querySelector(".product-show__arrow"),
            { yPercent: -150 },
            { yPercent: 0, duration: 1.5 },
            "-=1.5"
          )
          .fromTo(
            item.querySelector(".product-show__image"),
            { yPercent: -50 },
            { yPercent: 0, duration: 1.5 },
            "-=1.5"
          );
      });
    }
    const aboutTrain = document.querySelectorAll(".about-training");
    if (aboutTrain) {
      const aboutTraining = gsap.timeline({
        scrollTrigger: {
          trigger: ".about-training",
          start: "top bottom",
          end: `bottom center`,
          scrub: 0.5,
          markers: false,
        },
      });
      aboutTraining
        .fromTo(
          ".about-training__image",
          { top: -40 },
          { top: 200, duration: 0.5 }
        )
        .fromTo(
          ".about-training .circle-advantage",
          { yPercent: 50 },
          { yPercent: -100, duration: 0.5 },
          "-=0.5"
        );
    }
    const franchiseContent = document.querySelectorAll(".franchise-content");
    if (franchiseContent) {
      const franchiseBlock = gsap.timeline({
        scrollTrigger: {
          trigger: ".franchise-content",
          start: "top bottom",
          end: `center bottom`,
          scrub: 0.5,
          markers: false,
        },
      });
      franchiseBlock.fromTo(
        ".franchise-content",
        { scale: 1.1 },
        { scale: 1, duration: 0.5 }
      );
    }
  })();

  (function fadeInTriggers() {
    const boxes = document.querySelectorAll("[data-animation-fade]");
    if (!boxes) return;

    gsap.registerPlugin(ScrollTrigger);

    boxes.forEach((box, i) => {
      const fadeFrom = box.dataset.animationFadeFrom || 50;
      const fadeDelay = box.dataset.animationFadeDelay || 0;

      gsap.set(box, { autoAlpha: 0, y: fadeFrom });

      const anim = gsap.to(box, {
        duration: 0.8,
        autoAlpha: 1,
        delay: fadeDelay,
        y: 0,
        paused: true,
      });
      ScrollTrigger.create({
        trigger: box,
        end: "bottom bottom",
        once: true,
        onEnter: (self) => {
          if (self.progress === 1) {
            anim.progress(1);
          } else {
            anim.play();
          }
        },
      });
    });
  })();

  //   SmoothScroll({
  //     // Время скролла 400 = 0.4 секунды
  //     animationTime: 100,
  //     // Размер шага в пикселях
  //     stepSize: 85,
  //     // Дополнительные настройки:
  //     // Ускорение
  //     accelerationDelta: 30,
  //     // Максимальное ускорение
  //     accelerationMax: 2,
  //     // Поддержка клавиатуры
  //     keyboardSupport: true,
  //     // Шаг скролла стрелками на клавиатуре в пикселях
  //     arrowScroll: 50,
  //     // Pulse (less tweakable)
  //     // ratio of "tail" to "acceleration"
  //     pulseAlgorithm: true,
  //     pulseScale: 4,
  //     pulseNormalize: 1,
  //     // Поддержка тачпада
  //     touchpadSupport: true,
  //   });
}
